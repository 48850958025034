define("ember-svg-jar/inlined/social-inbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.613 3.087c0-.683-.554-1.237-1.238-1.237H4.713A3.712 3.712 0 001 5.562v18.225A3.712 3.712 0 004.713 27.5h15.524a3.712 3.712 0 003.713-3.713v-8.662a1.238 1.238 0 00-2.475 0v8.662c0 .684-.554 1.238-1.238 1.238h-.69a4.653 4.653 0 00-4.652-4.607h-4.652a4.653 4.653 0 00-4.653 4.607h-.877a1.237 1.237 0 01-1.238-1.238V5.562c0-.683.554-1.237 1.238-1.237h8.662c.684 0 1.238-.554 1.238-1.238zm.282 19.657a2.326 2.326 0 012.326 2.28H7.917a2.326 2.326 0 012.325-2.28h4.653zm-4.885-7.91a2.559 2.559 0 115.118 0 2.559 2.559 0 01-5.118 0zm2.559-4.884a4.885 4.885 0 100 9.77 4.885 4.885 0 000-9.77zm13.11-6.557c0-.279-.185-.464-.464-.464h-6.822c-.279 0-.464.185-.464.464V8.5c0 .279.185.464.464.464h2.43v1.44l2.114-1.44h2.278c.279 0 .464-.185.464-.464V3.393zM18.393 2h6.822c.79 0 1.393.604 1.393 1.393V8.5c0 .79-.604 1.393-1.393 1.393h-2l-3.321 2.321V9.893h-1.501C17.603 9.893 17 9.289 17 8.5V3.393C17 2.603 17.604 2 18.393 2z\" fill=\"#323B43\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.655 8.551a.5.5 0 01.282-.087h2.242V3.43h-6.75v5.035h2.393a.5.5 0 01.5.5v.494l1.333-.907zm-1.833 1.853v-1.44h-2.43c-.278 0-.463-.185-.463-.464V3.393c0-.279.185-.464.464-.464h6.822c.279 0 .464.185.464.464V8.5c0 .279-.185.464-.464.464h-2.278l-2.115 1.44zm-.928 1.81V9.893h-1.501C17.603 9.893 17 9.289 17 8.5V3.393C17 2.603 17.604 2 18.393 2h6.822c.79 0 1.393.604 1.393 1.393V8.5c0 .79-.604 1.393-1.393 1.393h-2l-3.321 2.321zm3.479-1.821h1.842A1.868 1.868 0 0027.108 8.5V3.393A1.868 1.868 0 0025.215 1.5h-6.822A1.868 1.868 0 0016.5 3.393V8.5c0 1.065.827 1.893 1.893 1.893h1v1.821a.5.5 0 00.787.41l3.192-2.231z\" fill=\"#323B43\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});